import { gql } from '@apollo/client';

const GET_AUTOMATIC_EVALUATION_TRIGGER_VARIABLES = gql`
  query getAutomaticEvaluationTriggerVariables($maintainerId: String!) {
    getAutomaticEvaluationTriggerVariables(maintainerId: $maintainerId) {
      id
      variable
      variableLabel
      operatorDisplay
      comparedToVariable
      comparedToVariableLabel
    }
  }
`;

export default GET_AUTOMATIC_EVALUATION_TRIGGER_VARIABLES;
