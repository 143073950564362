import { gql } from '@apollo/client';

const EDIT_AUTOMATIC_EVALUATION_MAINTAINER = gql`
  mutation EditAutomaticEvaluationMaintainer($maintainerId: String!) {
    editAutomaticEvaluationMaintainer(maintainerId: $maintainerId) {
      maintainer {
        id
        active
      }
    }
  }
`;

export default EDIT_AUTOMATIC_EVALUATION_MAINTAINER;
