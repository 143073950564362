import { gql } from '@apollo/client';

const GET_AUTOMATIC_EVALUATION_MAINTAINER_VARIABLES = gql`
  query getAutomaticEvaluationMaintainerVariables($maintainerId: String!) {
    getAutomaticEvaluationMaintainerVariables(maintainerId: $maintainerId) {
      id
      order
      active
      resolutionDisplay
      resolutionComment
      variable {
        id
        variable
        variableLabel
        operatorDisplay
        comparedToVariable
        comparedToVariableLabel
      }
    }
  }
`;

export default GET_AUTOMATIC_EVALUATION_MAINTAINER_VARIABLES;
