import { gql } from '@apollo/client';

const GET_AUTOMATIC_EVALUATION_MAINTAINERS = gql`
  query getAutomaticEvaluationMaintainers(
    $first: Int
    $offset: Int
    $orderBy: String
    ) {
    getAutomaticEvaluationMaintainers(
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          active
          order
          priority
          description
        }
      }
    }
  }
`;

export default GET_AUTOMATIC_EVALUATION_MAINTAINERS;
