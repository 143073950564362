import { gql } from '@apollo/client';

const CREATE_AUTOMATIC_EVALUATION_MAINTAINER = gql`
  mutation CreateAutomaticEvaluationMaintainer(
    $description: String!,
    $priority: String!,
    $order: String!,
    $triggerVariables: [AutomaticMaintainerVariableInputType]!,
    $decisionVariables: [AutomaticMaintainerDecisionVariableInputType]!
  ) {
    createAutomaticEvaluationMaintainer(
      description: $description,
      priority: $priority,
      order: $order,
      triggerVariables: $triggerVariables,
      decisionVariables: $decisionVariables
      ) {
      maintainer {
        id
      }
    }
  }
`;

export default CREATE_AUTOMATIC_EVALUATION_MAINTAINER;
