import { gql } from '@apollo/client';
import { MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';
import { CreditLineFields } from './fragments';

export const CREDIT_LINES = gql`
  query creditLines(
    $first: Int
    $offset: Int
    $masterEntity_Id: ID
    $creditlineType: String
    $debtorName: String
  ) {
    creditLines(
      first: $first
      offset: $offset
      masterEntity_Id: $masterEntity_Id
      creditlineType: $creditlineType
      debtorName: $debtorName
    ) {
      totalCount
      edges {
        cursor
        node {
          ...CreditLineFields
          masterEntity {
            ...MasterEntityBasicField
          }
          debtor {
            ...MasterEntityBasicField
          }
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
`;

export const MASTER_ENTITY_CREDIT_LINES = gql`
  query masterEntity($masterEntityId: Int!, $currency: String) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      creditLine(currency: $currency) {
        id
        lastMovement {
          id
          totalCreditUsed {
            ...MoneyFields
          }
          amount {
            ...MoneyFields
          }
          createdAt
        }
        currentLimit {
          id
          createdAt
          newLimit {
            ...MoneyFields
          }
        }
        limitChanges {
          id
          createdAt
          newLimit {
            ...MoneyFields
          }
        }
        movements {
          totalCount
          totalPages
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              amount {
                ...MoneyFields
              }
              totalCreditUsed {
                ...MoneyFields
              }
            }
          }
        }
        creditLineEvaluationRequestsLast {
          id
          createdAt
          updatedAt
          requestDate
          status
          reEvaluationDate
          resolvedDate
          approvedAmount {
            ...MoneyFields
          }
          user {
            id
            firstName
            lastName
          }
          comment
          creditlineevaluationvariablesSet {
            id
            debt {
              ...MoneyFields
            }
            avgMonthlyCessions {
              ...MoneyFields
            }
            avgInternalRate
            estimatedInterest {
              ...MoneyFields
            }
            rejectionsPercent
            fixedAssetsPurchasesLastYear {
              ...MoneyFields
            }
            fixedAssets {
              ...MoneyFields
            }
            ipc
            monetaryPolicyRate
            spread
            avgOperationTerm
            creditlineevaluationindicatorsSet {
              id
              createdAt
              legalRepresentativeDicom
              creditorsQuantity
              internalLeverage
              debtMultiplier
              customersConcentration
              dicomRate
              estimatedFlow {
                ...MoneyFields
              }
              bankCreditline {
                ...MoneyFields
              }
              flowCreditline {
                ...MoneyFields
              }
              profitCreditline {
                ...MoneyFields
              }
            }
          }
        }
      }
      receiverCessionsHistory {
        id
        cessionsNumber
        cessionsMoneyAmount
        historicCessionsNumber
        historicCessionsMoneyAmount
      }
      company {
        id
        cessionsHistory {
          id
          cessionsNumber
          cessionsMoneyAmount
          historicCessionsNumber
          historicCessionsMoneyAmount
        }
      }
    }
  }
  ${MoneyFields}
`;
