import { gql } from '@apollo/client';
import { MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';
import { CreditLineFields } from './fragments';

export const CREATE_CREDIT_LINE = gql`
  mutation CreateCreditLine(
    $masterEntityId: String!
    $currency: String!
    $limit: Float!
  ) {
    createCreditLine(
      masterEntityId: $masterEntityId
      currency: $currency
      limit: $limit
    ) {
      masterEntity {
        ...MasterEntityBasicField
        creditlines {
          edges {
            node {
              ...CreditLineFields
            }
          }
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
`;

export const CREATE_SUBLINES_FROM_FILE = gql`
  mutation CreateSublinesFromFile($file: Upload!) {
    createSublinesFromFile(file: $file) {
      success
      message
      creditLines {
        ...CreditLineFields
      }
    }
  }
  ${CreditLineFields}
`;
